<script setup lang="ts">
import type { IDynamicComponentsOption } from "~/utils/components";
import { DateTime } from 'luxon'

interface Props {
  modelValue: string | undefined;
  options: IDynamicComponentsOption;
}

const props = withDefaults(defineProps<Props>(), {});

const emit = defineEmits<{
  "update:modelValue": [payload: string];
}>();
const { elementMarginTop, elementDensity } = useRuntimeConfig().public.forms;
const classObject = computed(() => `mt-${elementMarginTop}`)

const optionsDate = { mask: '##.##.####' }

const menu = ref(false)

const updateValue = (e: string) => {
  console.log(`updateValue`, e);
  emit("update:modelValue", e)

};
// const updateValue = (e: Event) => {
//   emit("update:modelValue", (e.target as HTMLInputElement).value);
// };

const updateDatePickerModelValue = (e: Date) => {
  emit("update:modelValue", DateTime.fromJSDate(e).toFormat('dd.MM.yyyy'));
  console.log(`updateDatePickerModelValue`, DateTime.fromJSDate(e).toFormat('dd.MM.yyyy'));
}
const dateFromString = computed(() => {
  const date = props.modelValue ? DateTime.fromFormat(props.modelValue, 'dd.MM.yyyy').toISO() : undefined
  console.log(`dateFromString`, date);

  return date
})
const title = props.options.label ? props.options.label : 'Выбор даты'

</script>

<template>
  <div :class="classObject">

    <v-locale-provider locale="ru">
      <v-date-input :label="options.label"
                    hide-header
                    placeholder="дд.мм.гггг"
                    hide-weekdays
                    :value="modelValue"
                    :density="elementDensity"
                    @update:model-value="updateDatePickerModelValue"></v-date-input>
    </v-locale-provider>

  </div>


</template>

<style scoped></style>
