<script setup lang="ts">
import { storeToRefs } from "pinia";
import { useProjectsStore } from "@/stores/db/projects";
import { type IUser } from "@/stores/db/users";

definePageMeta({
  layout: 'dashboard-fluid',
  auth: false
})


interface IRedmineResponse {
  success: boolean;
  issueId: number;
}

const { success, info, warning, error, clear } = useAlertStore();
const { userFormsByProject, currentProject } = storeToRefs(useProjectsStore());
const { fetchUserFormsByProject } = useProjectsStore();

const formId = +useRoute().params.id;
const formNotFound = ref(false);



// после отправки формы
const handleDispatch = async (formId: number, issueId: number) => {
  await navigateTo({
    path: "/dispatch/done",
    query: {
      form: formId,
      issue: issueId,
    },
  });
};


const findFormTitle = () =>
  userFormsByProject.value?.find((item) => item.form_id === formId)?.form_title;

useHead({
  title: `${currentProject.value?.title} - ${findFormTitle()}`,
});

await fetchUserFormsByProject();

cs(`pages.dispatch.id: ${formId}`);
</script>

<template>
  <div>

    <v-row>
      <v-col cols="12"
             md="10"
             lg="10"
             offset-lg="1"
             offset-md="1">
        <DispatchForm :form-id="+$route.params.id"
                      @@end-dispatch="handleDispatch" />
      </v-col>
    </v-row>
  </div>
</template>

<style scoped></style>
