<script setup lang="ts">
import type { IDynamicComponentsOption } from "~/utils/components";
import { useRules } from "~/composables/useRules";

interface IRadioButton {
  title: string;
  color: string;
}

interface Props {
  modelValue: string | undefined;
  options: IDynamicComponentsOption;
}

const props = withDefaults(defineProps<Props>(), {});
const { ruleRequired } = useRules();

const emit = defineEmits<{
  "update:modelValue": [payload: string];
}>();

const updateValue = (e: Event) => {
  emit("update:modelValue", radios.value);
};

const items = ref<IRadioButton[]>([]);
const radios = ref<string>("");



const selected = ref<string[]>([])
const foo = ref<string[]>()

const handleClickSelect = (event: any) => {
  // console.log(`handleClickSelect`, event)
}
const handleUpdateSelected = (event: any) => {
  let result = ''
  selected.value?.forEach(item => {
    if (result === '') result = item
    else result = result + ', ' + item
  })
  emit("update:modelValue", result);
  // console.log(`handleUpdateSelected`, event)
}

const initCheckList = () => {
  const arrayValues = props.options.settings?.split(/\r?\n/);
  let color: string;
  arrayValues?.forEach((item) => {
    const title = item.split("$")[0];
    if (item.split("$")[1]) {
      color = item.split("$")[1].trim();
    }
    items.value.push({ title, color });
  });

};
const setDeafultValues = () => {
  const arrayValues = props.options.defaultValue?.split(/\r?\n/);

  arrayValues?.forEach((item) => {
    selected.value?.push(item);
  });

};


initCheckList();
setDeafultValues()
// if (props.options.defaultValue) {
//   radios.value = props.options.defaultValue;
// }
</script>

<template>
  <div>
    <v-list v-model:selected="selected"
            select-strategy="classic"
            @click:select="handleClickSelect"
            @update:selected="handleUpdateSelected">
      <v-list-subheader>{{ options.label }}</v-list-subheader>

      <v-list-item v-for="item in items"
                   :key="item.title"
                   :value="item.title">
        <template #prepend="{ isActive }">
          <v-list-item-action start>
            <!-- <v-switch color="primary" inset :model-value="isActive" /> -->
            <v-checkbox-btn color="primary"
                            :model-value="isActive" />
          </v-list-item-action>
        </template>

        <v-list-item-title>{{ item.title }}</v-list-item-title>
      </v-list-item>
    </v-list>
  </div>
</template>

<style scoped></style>
