<script setup lang="ts">
import type { IDynamicComponentsOption } from "~/utils/components";
import { useRules } from "~/composables/useRules";

interface Props {
  modelValue: string | undefined;
  options: IDynamicComponentsOption;
}

const props = withDefaults(defineProps<Props>(), {});

const emailRules = ref([
  (v: any) => !!v || 'Поле обязательно для заполнения',
  (v: string) => /^(([^<>()[\]\\.,;:\s@']+(\.[^<>()\\[\]\\.,;:\s@']+)*)|('.+'))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(v) || 'Неверный формат электронной почты',
])

const emit = defineEmits<{
  "update:modelValue": [payload: string];
}>();

const updateValue = (e: string) => {
  emit("update:modelValue", e);
};
// const updateValue = (e: Event) => {
//   emit("update:modelValue", (e.target as HTMLInputElement).value);
// };

const { ruleRequired } = useRules();
const { elementMarginTop, elementDensity } = useRuntimeConfig().public.forms;
const classObject = computed(() => `mt-${elementMarginTop}`)

console.log(`email: `, ruleRequired.value);

</script>

<template>
  <div :class="classObject">
    <v-text-field :label="options.label"
                  :hint="options.hint"
                  :persistent-hint="options.isPersistentHint"
                  :disabled="options.disabled"
                  :value="modelValue"
                  @update:model-value="updateValue"
                  :density="elementDensity"
                  type="email"
                  :rules="options.isRequired ? emailRules : undefined"
                  :required="options.isRequired ? ruleRequired : undefined"></v-text-field>
  </div>
</template>

<style scoped></style>
