<script setup lang="ts">
import type { IDynamicComponentsOption } from "~/utils/components";
import { useRules } from "~/composables/useRules";

interface IRadioButton {
  title: string;
  color: string;
}

interface Props {
  modelValue: string | undefined;
  options: IDynamicComponentsOption;
}

const props = withDefaults(defineProps<Props>(), {});
const { ruleRequired } = useRules();

const emit = defineEmits<{
  "update:modelValue": [payload: string];
}>();

const updateValue = (e: Event) => {
  emit("update:modelValue", radios.value);
};

const radioButtons = ref<IRadioButton[]>([]);
const radios = ref<string>("");

const initRadioGroup = () => {
  const arrayValues = props.options.settings?.split(/\r?\n/);
  let color: string;
  arrayValues?.forEach((item) => {
    const title = item.split("$")[0];
    if (item.split("$")[1]) {
      color = item.split("$")[1].trim();
    }
    radioButtons.value.push({ title, color });
  });

};
initRadioGroup();
if (props.options.defaultValue) {
  radios.value = props.options.defaultValue;
}
</script>

<template>
  <div>
    <v-radio-group :label="options.label"
                   v-model="radios"
                   :hint="options.hint"
                   :persistent-hint="options.isPersistentHint"
                   :disabled="options.disabled"
                   :rules="options.isRequired ? ruleRequired : undefined"
                   @update:model-value="updateValue">
      <v-radio v-for="item in radioButtons"
               :label="item.title"
               :value="item.title"
               :color="item.color">
        <template v-slot:label>
          <div :style="{ color: `${item.color}` }">
            {{ item.title }}
          </div>
        </template>
      </v-radio>
    </v-radio-group>
  </div>
</template>

<style scoped></style>
