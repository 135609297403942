<script setup lang="ts">
import { type IDirectoryValue } from "~/stores/db/dirs";
import type { IDynamicComponentsOption } from "~/utils/components";

const props = defineProps<{
  modelValue: string;
  options: IDynamicComponentsOption;
}>();
const emit = defineEmits<{
  // (e: "update:modelValue", payload: string): void;
  "update:modelValue": [payload: string];
}>();

const checked = ref(false);
const items = ref<IDirectoryValue[]>();
const value = ref<IDirectoryValue | IDirectoryValue[]>();

const fetchDirectory = async () => {
  if (props.options.directoryId) {
    const { data, error } = await useCustomFetch<IDirectoryValue[]>(
      `/directories/value/${props.options.directoryId}`
    );
    if (error.value) {
      console.log(
        `[dashboard.DirectorySwitchField.fetchDirectory] error: `,
        error.value
      );
    } else {
      items.value = data.value as IDirectoryValue[];
      // console.log(items.value);
    }
  } else {
    items.value = [] as IDirectoryValue[];
  }
};

watch(checked, (newVal) => {
  if (!checked.value) {
    emit("update:modelValue", "");
    return;
  }

  if (props.options.isUseValue) {
    if (props.options.isMultiple) {
      ce("components.dynamic.DirectorySwitchField", "TODO");
    } else {
      emit("update:modelValue", value.value?.value as string);
    }
  } else {
    if (props.options.isMultiple) {
      let result: string = "";
      if (value.value && Array.isArray(value.value))
        value.value.forEach((el: IDirectoryValue) => {
          if (result) result = result + ", " + el.title;
          else result = el.title;
        });

      // cl("components.dynamic.DirectoryField", result);
      emit("update:modelValue", result);
    } else {
      emit("update:modelValue", value.value?.title as string);
    }
  }
});

watch(value, (newVal) => {
  // console.log(newVal);
  if (checked.value) {
    if (props.options.isUseValue) {
      if (props.options.isMultiple) {
        ce("components.dynamic.DirectorySwitchField", "TODO");
      } else {
        emit("update:modelValue", value.value?.value as string);
      }
    } else {
      if (props.options.isMultiple) {
        let result: string = "";
        if (value.value && Array.isArray(value.value))
          value.value.forEach((el: IDirectoryValue) => {
            if (result) result = result + ", " + el.title;
            else result = el.title;
          });

        // cl("components.dynamic.DirectoryField", result);
        emit("update:modelValue", result);
      } else {
        emit("update:modelValue", value.value?.title as string);
      }
    }
  } else {
    emit("update:modelValue", "");
  }
});
await fetchDirectory();

if (props.options.defaultValue) {
  if (props.options.isMultiple) {
    const titles: Array<string> = props.options.defaultValue.split(",");
    value.value = [];
    if (items.value) {
      titles.forEach((title) => {
        const found = items.value.find((item) => item.title === title.trim());
        if (found) {
          value.value!.push(found);
        }
      });
    }
  } else
    value.value = items.value?.find(
      (item) => item.title === props.options.defaultValue
    );
}
// console.log(`switch-directory: ${JSON.stringify(props.options)}`);
const { elementMarginTop, elementDensity } = useRuntimeConfig().public.forms;
const classObject = computed(() => `mt-${elementMarginTop}`)
</script>

<template>
  <div :class="classObject">
    <v-switch v-model="checked"
              inset
              :label="options.label"
              :disabled="options.disabled"
              :color="checked ? 'blue-darken-3' : 'red'"
              :persistent-hint="options.isPersistentHint" />

    <v-autocomplete v-if="checked"
                    v-model="value"
                    :items="items"
                    auto-select-first
                    return-object
                    item-title="title"
                    item-value="id"
                    clearable
                    :density="elementDensity"
                    :label="options.labelChild"
                    :hint="options.hintChild"
                    :persistent-hint="options.isPersistentHintChild"
                    :multiple="options.isMultiple"></v-autocomplete>
  </div>
</template>

<style scoped></style>
