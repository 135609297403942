<script setup lang="ts">
import { type IDirectoryValue } from "~/stores/db/dirs";
import type { IDynamicComponentsOption } from "~/utils/components";

const props = defineProps<{
  modelValue: string;
  options: IDynamicComponentsOption;
}>();
const emit = defineEmits<{
  // (e: "update:modelValue", payload: string): void;
  "update:modelValue": [payload: string];
}>();

const checked = ref(false);
const items = ref<IDirectoryValue[]>();
const itemsSecond = ref<IDirectoryValue[]>();

const values = ref<{
  val1: IDirectoryValue | IDirectoryValue[] | undefined;
  val2: IDirectoryValue | IDirectoryValue[] | undefined;
}>({
  val1: props.options.isMultiple ? [] : undefined,
  val2: props.options.isMultipleSecond ? [] : undefined,
});

const fetchDirectory = async () => {
  if (props.options.directoryId) {
    const { data, error } = await useCustomFetch<IDirectoryValue[]>(
      `/directories/value/${props.options.directoryId}`
    );
    if (error.value) {
      console.log(
        `[dashboard.DirectorySwitchField.fetchDirectory] error: `,
        error.value
      );
    } else {
      items.value = data.value as IDirectoryValue[];
      // console.log(items.value);
    }
  } else {
    items.value = [] as IDirectoryValue[];
  }
};

const fetchDirectorySecond = async () => {
  if (props.options.directoryIdSecond) {
    const { data, error } = await useCustomFetch<IDirectoryValue[]>(
      `/directories/value/${props.options.directoryIdSecond}`
    );
    if (error.value) {
      console.log(
        `[dashboard.DirectorySwitchField.fetchDirectory] error: `,
        error.value
      );
    } else {
      itemsSecond.value = data.value as IDirectoryValue[];
      // console.log(items.value);
    }
  } else {
    itemsSecond.value = [] as IDirectoryValue[];
  }
};

watch(checked, (newVal) => {
  if (!checked.value) emit("update:modelValue", "");
});
watch(
  [() => values.value.val1, () => values.value.val2],
  ([newVal1, newVal2]) => {
    let dir1: string = "";
    let dir2: string = "";

    if (checked.value) {
      // dir1
      if (props.options.isMultiple) {
        if (newVal1 && Array.isArray(newVal1))
          newVal1.forEach((el: IDirectoryValue) => {
            if (dir1) dir1 = dir1 + ", " + el.title;
            else dir1 = el.title;
          });
      } else {
        if (newVal1) dir1 = newVal1.title;
        else dir1 = "";
      }
      // dir2
      if (props.options.isMultipleSecond) {
        if (newVal2 && Array.isArray(newVal2))
          newVal2.forEach((el: IDirectoryValue) => {
            if (dir2) dir2 = dir2 + ", " + el.title;
            else dir2 = el.title;
          });
      } else {
        if (newVal2) dir2 = newVal2.title;
        else dir2 = "";
      }

      console.log(dir1, dir2);
      if (props.options.settings) {
        let result = props.options.settings.slice();
        result = result.replace("PARAM_1", dir1);
        result = result.replace("PARAM_2", dir2);
        // console.log(result);
        emit("update:modelValue", result);
      } else {
        emit("update:modelValue", dir1 + " - " + dir2);
      }
    } else {
      emit("update:modelValue", "");
    }
  }
);

await fetchDirectory();
await fetchDirectorySecond();

if (props.options.defaultValue) {
  if (props.options.isMultiple) {
    const titles: Array<string> = props.options.defaultValue.split(",");
    values.value.val1 = [];
    if (items.value) {
      titles.forEach((title) => {
        const found = items.value.find((item) => item.title === title.trim());
        if (found) {
          values.value!.val1.push(found);
        }
      });
    }
  } else
    values.value.val1 = items.value?.find(
      (item) => item.title === props.options.defaultValue
    );
}

if (props.options.defaultValueSecond) {
  if (props.options.isMultipleSecond) {
    const titles: Array<string> = props.options.defaultValueSecond.split(",");
    values.value.val2 = [];
    if (itemsSecond.value) {
      titles.forEach((title) => {
        const found = itemsSecond.value.find(
          (item) => item.title === title.trim()
        );
        if (found) {
          values.value!.val2.push(found);
        }
      });
    }
  } else
    values.value.val2 = itemsSecond.value?.find(
      (item) => item.title === props.options.defaultValueSecond
    );
}

if (props.options.defaultValue || props.options.defaultValueSecond) {
  checked.value = true;
}
// console.log(`switch-directory: ${JSON.stringify(props.options)}`);
const { elementMarginTop, elementDensity } = useRuntimeConfig().public.forms;
const classObject = computed(() => `mt-${elementMarginTop}`)
</script>

<template>
  <div :class="classObject">
    <v-switch v-model="checked"
              inset
              :label="options.label"
              :disabled="options.disabled"
              :color="checked ? 'blue-darken-3' : 'red'"
              :persistent-hint="options.isPersistentHint" />

    <v-autocomplete v-if="checked"
                    v-model="values!.val1"
                    :items="items"
                    auto-select-first
                    return-object
                    item-title="title"
                    item-value="id"
                    clearable
                    :density="elementDensity"
                    :label="options.labelChild"
                    :hint="options.hintChild"
                    :persistent-hint="options.isPersistentHintChild"
                    :multiple="options.isMultiple"></v-autocomplete>

    <v-autocomplete v-if="checked"
                    v-model="values!.val2"
                    :items="itemsSecond"
                    auto-select-first
                    return-object
                    item-title="title"
                    item-value="id"
                    clearable
                    :density="elementDensity"
                    :label="options.labelSecond"
                    :hint="options.hintSecond"
                    :persistent-hint="options.isPersistentHintSecond"
                    :multiple="options.isMultipleSecond"></v-autocomplete>
  </div>
</template>

<style scoped></style>
