<script setup lang="ts">
import type { IDynamicComponentsOption } from "~/utils/components";
import { useRules } from "~/composables/useRules";

const props = defineProps<{
  modelValue: string;
  options: IDynamicComponentsOption;
}>();
const emit = defineEmits<{
  // (e: "update:modelValue", payload: string): void;
  "update:modelValue": [payload: string];
}>();

const updateValue = (e: string) => {
  emit("update:modelValue", e);
};
// const updateValue = (e: Event) => {
//   emit("update:modelValue", (e.target as HTMLInputElement).value);
// };
const { ruleRequired } = useRules();

// console.log(`textarea: ${JSON.stringify(props.options)}`);
</script>

<template>
  <div>
    <v-textarea
      :label="options.label"
      :hint="options.hint"
      :persistent-hint="options.isPersistentHint"
      :disabled="options.disabled"
      :value="modelValue"
      :rows="options.rows ? options.rows : 3"
      @update:model-value="updateValue"
      :rules="options.isRequired ? ruleRequired : undefined"
    ></v-textarea>
  </div>
</template>

<style scoped></style>
