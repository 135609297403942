<script setup lang="ts">
import type { IDynamicComponentsOption } from "~/utils/components";
import { useRules } from "~/composables/useRules";

const props = defineProps<{
  options: IDynamicComponentsOption;
}>();
const emit = defineEmits<{
  // (e: "update:modelValue", payload: string): void;
  "update:modelValue": [payload: string];
}>();

const updateValue = (e: Event) => {
  emit("update:modelValue", (e.target as HTMLInputElement).value);
};

const { ruleRequired } = useRules();
// console.log(`text: ${JSON.stringify(props.options)}`);

const fileRules = ref([
  (files: any) =>
    !files ||
    !files.some((file: any) => file.size > 2e6) ||
    "Размер файлов не должен превышать 2Мб",
]);

const { elementMarginTop, elementDensity } = useRuntimeConfig().public.forms;
const classObject = computed(() => `mt-${elementMarginTop}`)
</script>

<template>
  <div :class="classObject">
    <v-file-input show-size
                  counter
                  chips
                  multiple
                  :density="elementDensity"
                  :label="options.label"
                  :hint="options.hint"
                  :persistent-hint="options.isPersistentHint"
                  :disabled="options.disabled"
                  :rules="fileRules" />
  </div>
</template>

<style scoped></style>
