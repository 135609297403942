<script setup lang="ts">
import { type IDynamicComponentsOption } from "~/utils/components";

const props = defineProps<{
  modelValue: string;
  options: IDynamicComponentsOption;
}>();
const emit = defineEmits<{
  (e: "update:modelValue", payload: string): void;
}>();

const checked = ref(false);

const updateValue = (e: string) => {
  emit("update:modelValue", e);
};
// const updateValue = (e: Event) => {
//   emit("update:modelValue", (e.target as HTMLInputElement).value);
// };

watch(checked, (newVal) => {
  if (!checked.value) emit("update:modelValue", "");
});

if (props.options.defaultValue) {
  checked.value = true;
}

// console.log(`switch-text: ${JSON.stringify(props.options)}`);
</script>

<template>
  <div >
    <v-switch
      v-model="checked"
      inset
      :label="options.label"
      :disabled="options.disabled"
      :color="checked ? 'blue-darken-3' : 'red'"
      :persistent-hint="options.isPersistentHint"
    />

    <v-text-field
      v-if="checked"
      @update:model-value="updateValue"
      :value="modelValue"
      :label="options.labelChild"
      :hint="options.hintChild"
      :persistent-hint="options.isPersistentHintChild"
    ></v-text-field>
  </div>
</template>

<style scoped>
label {
  text-indent: 1em;
}
label:before {
  background-color: lightgray;
  content: "";
  width: 2em;
  height: 1em;
  position: absolute;
  left: 0;
}

.track {
  background-color: gray;
  position: absolute;
  width: 1em;
  height: 1em;
  left: 0;
  top: 0;
}

input[type="checkbox"] {
  opacity: 0;
}
input[type="checkbox"]:checked + .track {
  left: 1em;
  background-color: #e63946;
}

.busy-text {
  color: #e63946;
  caret-color: #e63946;
  font-weight: bold;
}

.available-text {
  color: #07beb8;
  caret-color: #07beb8;
  font-weight: bold;
}

label {
  user-select: none;
  cursor: pointer;
  line-height: 1em;
  transform: scale(1.8);
}
label:before {
  background-color: rgba(233, 227, 227, 0.904);
  border: solid 1px rgba(0, 0, 0, 0.5);
  border-radius: 1em;
}

.track {
  background-color: #07beb8;
  transition: left 100ms;
  border-radius: 50%;
  box-shadow: 0 2px 1px rgba(0, 0, 0, 0.25);
}
</style>
