<script setup lang="ts">
import { type IDynamicComponentsOption } from "~/utils/components";

const props = defineProps<{
  modelValue: boolean;
  options: IDynamicComponentsOption;
}>();
const emit = defineEmits<{
  (e: "update:modelValue", payload: boolean): void;
  // "update:modelValue": [payload: boolean];
}>();

const value = computed({
  get() {
    // console.log(`switch. get: ${JSON.stringify(props.modelValue)}`);
    return props.modelValue;
  },
  set(value) {
    emit("update:modelValue", value);
    // console.log(`switch. set: ${JSON.stringify(value)}`);
  },
});
// console.log(`switch: ${JSON.stringify(props.options)}`);
</script>

<template>
  <div>
    <v-switch
      v-model="value"
      inset
      :label="options.label"
      :disabled="options.disabled"
      :color="value ? 'blue-darken-3' : 'red'"
      :persistent-hint="options.isPersistentHint"
    />
  </div>
</template>

<style scoped></style>
