<script setup lang="ts">
import type { IDynamicComponentsOption } from "~/utils/components";
import { useRules } from "~/composables/useRules";
import type { IDirectoryValue } from "~/stores/db/dirs";


interface IRedmineParam {
  id: number;
  name: string;
  active: boolean;
  is_default: boolean;
}

const props = defineProps<{
  modelValue: number;
  projectId: number,
  options: IDynamicComponentsOption;
}>();
const emit = defineEmits<{
  // (e: "update:modelValue", payload: string): void;
  "update:modelValue": [payload: number];
}>();

// const items = ref<IRedmineParam[]>();
const value = ref<IRedmineParam>();

const { ruleRequired } = useRules();

const updateValue = (e: Event) => {
  emit("update:modelValue", value.value.id);
};


const { data: items } = await useCustomFetch<IRedmineParam[]>(
  `/redmine/enums/`, {
  method: 'POST',
  body: {
    projectId: props.projectId,
    link: props.options.redmineParamLink
  }
}
);

// filter by active
items.value = items.value.filter(item => item.active === true) as IRedmineParam[]
// priority
if (props.options.redmineParamName === 'priority_id') {
  const { data } = await useCustomFetch<IDirectoryValue[]>('directories/value/101')

  if (data.value) {
    items.value.map(item => {
      const found = data.value.find(v => Number(v.value) === item.id)
      if (found) {
        // console.log(item.name, found.title);

        return item.name = found.title
      }
      else {
        // console.log(`not found`, item.id);
      }
      // return item.name = `${item.id} ${item.name}`
    })
  }

}


const defaultValue = items.value?.find(item => item.is_default === true)
if (defaultValue)
  value.value = defaultValue


// console.log(`directory-redmine: ${JSON.stringify(props.options)}`);
const { elementMarginTop, elementDensity } = useRuntimeConfig().public.forms;
const classObject = computed(() => `mt-${elementMarginTop}`)
</script>

<template>
  <div :class="classObject">
    <!-- <p>value: {{ value }}</p> -->
    <!-- <pre>{{ items }}</pre> -->
    <v-autocomplete v-model="value"
                    :items="items"
                    :label="options.label"
                    :hint="options.hint"
                    :disabled="options.disabled"
                    :persistent-hint="options.isPersistentHint"
                    auto-select-first
                    return-object
                    clearable
                    item-title="name"
                    item-value="id"
                    :density="elementDensity"
                    @update:model-value="updateValue"
                    :rules="options.isRequired ? ruleRequired : undefined"></v-autocomplete>

  </div>
</template>

<style scoped></style>
