export const useRules = () => {
  //   const ruleRequired = (v: any) => !!v || "Поле обязательно для заполнения";

  const ruleRequired = ref([
    (v: any) => !!v || "Поле обязательно для заполнения",
    // (v: any) => (v && v.length <= 10) || "Name must be less than 10 characters",
  ]);

  return { ruleRequired };
};
