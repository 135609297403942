<script setup lang="ts">
import { type IFormCard } from "@/types";
import { useProjectsStore } from "~/stores/db/projects";

const props = defineProps<{
  projectId: number;
  card: IFormCard;
  isDuplicate: boolean;
  userGroups: string[];
  values: any;
}>();

const emit = defineEmits<{
  // (e: "@removeCard", index: number): void;
  // (e: "@addCard", index: number): void;
  "@addCard": [index: number];
  "@removeCard": [index: number];
  "@handleFiles": [files: any];
}>();

const { data: sessionData, signOut } = useAuth()
const loggedIn = computed(() => useAuth().data.value?.accessToken ? true : false)
const { currentProject, guestProjectId } = storeToRefs(useProjectsStore())

const handleAdd = () => {
  // cardsArray.value?.push({ ...props.card });
  emit("@addCard", props.card.index);
};



const handleCloseButton = () => {
  cl("!!!!  handleCloseButton", `index: ${props.card.index}`);
  // cardsArray.value.splice(index, 1);
  emit("@removeCard", props.card.index);
};

const buildTitle = () => {
  if (props.isDuplicate) {
    if (props.card.index == 0) return props.card.title;
    else return props.card.title + ` #${props.card.index + 1}`;
  } else {
    return props.card.title;
  }
};
const isCloseButton = () => {
  if (!props.isDuplicate) return false;
  else if (props.card.index === 0) return false;
  else return true;
};

const checkPermissions = (groups: string[] | undefined): boolean => {
  let result = false;
  if (!loggedIn.value) { // FIX delete this
    return true
  }
  if (props.userGroups) {
    for (let i = 0; i < props.userGroups.length; i++) {
      if (groups!.includes(props.userGroups[i])) {
        result = true;
      }
    }
  }

  return result;
};

const buildValueKey = (key: string) => {
  if (props.isDuplicate) {
    return key + "-" + props.card.index;
  } else return key;
};

const handleChangeFiles = (event: any) => {
  emit("@handleFiles", event.target.files);
};
// if (props.isDuplicate) {
//   console.log("val", JSON.stringify(props.values));
//   console.log("card", "index: " + JSON.stringify(props.card.index));
// }

// cl('card', JSON.stringify(props.card));


</script>

<template>
  <div>
    <!-- <p>currentProject : {{ currentProject }}</p> -->
    <v-card class="mb-2"
            variant="flat"
            :color="card.color">
      <div v-if="isDuplicate"
           class="d-flex justify-space-between align-center">
        <v-card-title>
          <div class="d-inline-block text-wrap">
            {{ buildTitle() }}
          </div>
        </v-card-title>
        <v-btn v-if="isCloseButton()"
               @click="handleCloseButton()"
               variant="text"
               icon="mdi-close-circle"></v-btn>
      </div>
      <div v-else>
        <v-card-title class="text-wrap">
          {{ buildTitle() }}
        </v-card-title>
      </div>
      <v-card-text v-if="isDuplicate">
        <div class="py-0"
             v-for="field in card.fields"
             :key="field.id">
          <DynamicFileField v-if="field.type === 'file' && checkPermissions(field.groups)"
                            :options="{
                              label: field.label,
                              hint: field.hint,
                              isPersistentHint: field.isPersistentHint,
                            }"
                            @change="handleChangeFiles" />

          <DynamicTextField v-if="field.type === 'text' && checkPermissions(field.groups)"
                            v-model="values[buildValueKey(field.localName)]"
                            :options="{
                              label: field.label,
                              hint: field.hint,
                              isPersistentHint: field.isPersistentHint,
                              isRequired: field.isRequired,
                              defaultValue: field.defaultValue,
                              mask: field.mask
                            }" />
          <DynamicEmailField v-if="field.type === 'email' && checkPermissions(field.groups)"
                             v-model="values[buildValueKey(field.localName)]"
                             :options="{
                              label: field.label,
                              hint: field.hint,
                              isPersistentHint: field.isPersistentHint,
                              isRequired: field.isRequired,
                              defaultValue: field.defaultValue,
                            }" />
          <DynamicRadioGroupField v-if="field.type === 'radio-buttons' && checkPermissions(field.groups)
          "
                                  v-model="values[buildValueKey(field.localName)]"
                                  :options="{
                                    label: field.label,
                                    hint: field.hint,
                                    isPersistentHint: field.isPersistentHint,
                                    labelChild: field.labelChild,
                                    hintChild: field.hintChild,
                                    isPersistentHintChild: field.isPersistentHintChild,
                                    isRequired: field.isRequired,
                                    defaultValue: field.defaultValue,
                                    settings: field.settings,
                                  }" />
          <DynamicSwitchField v-if="field.type === 'switch' && checkPermissions(field.groups)"
                              v-model="values[buildValueKey(field.localName)]"
                              :options="{
                                label: field.label,
                                hint: field.hint,
                                isPersistentHint: field.isPersistentHint,
                                labelChild: field.labelChild,
                                hintChild: field.hintChild,
                                isPersistentHintChild: field.isPersistentHintChild,
                                isRequired: field.isRequired,
                                defaultValue: field.defaultValue,
                              }" />
          <DynamicSwitchTextField v-if="field.type === 'switch-text' && checkPermissions(field.groups)
          "
                                  v-model="values[field.localName]"
                                  :options="{
                                    label: field.label,
                                    hint: field.hint,
                                    isPersistentHint: field.isPersistentHint,
                                    labelChild: field.labelChild,
                                    hintChild: field.hintChild,
                                    isPersistentHintChild: field.isPersistentHintChild,
                                    isRequired: field.isRequired,
                                    defaultValue: field.defaultValue,
                                  }" />
          <DynamicTextAreaField v-if="field.type === 'textarea' && checkPermissions(field.groups)"
                                v-model="values[buildValueKey(field.localName)]"
                                :options="{
                                  label: field.label,
                                  hint: field.hint,
                                  rows: field.rows,
                                  isPersistentHint: field.isPersistentHint,
                                  isRequired: field.isRequired,
                                  defaultValue: field.defaultValue,
                                }" />
          <DynamicDirectoryField v-if="field.type === 'directory' && checkPermissions(field.groups)"
                                 v-model="values[buildValueKey(field.localName)]"
                                 :options="{
                                  label: field.label,
                                  hint: field.hint,
                                  directoryId: field.directoryId,
                                  isUseValue: field.isUseValue,
                                  isPersistentHint: field.isPersistentHint,
                                  isRequired: field.isRequired,
                                  isMultiple: field.isMultiple,
                                  defaultValue: field.defaultValue,
                                  isEnableOtherValue: field.isEnableOtherValue
                                }" />
          <DynamicDirectorySwitchField v-if="field.type === 'switch-directory' &&
            checkPermissions(field.groups)
          "
                                       v-model="values[buildValueKey(field.localName)]"
                                       :options="{
                                        label: field.label,
                                        hint: field.hint,
                                        directoryId: field.directoryId,
                                        isUseValue: field.isUseValue,
                                        isPersistentHint: field.isPersistentHint,
                                        labelChild: field.labelChild,
                                        hintChild: field.hintChild,
                                        isPersistentHintChild: field.isPersistentHintChild,
                                        isRequired: field.isRequired,
                                        isMultiple: field.isMultiple,
                                        defaultValue: field.defaultValue,
                                      }" />
          <DynamicTwoDirectorySwitchField v-if="field.type === 'two-directory' && checkPermissions(field.groups)
          "
                                          v-model="values[buildValueKey(field.localName)]"
                                          :options="{
                                            label: field.label,
                                            hint: field.hint,
                                            directoryId: field.directoryId,
                                            isUseValue: field.isUseValue,
                                            isPersistentHint: field.isPersistentHint,
                                            labelChild: field.labelChild,
                                            hintChild: field.hintChild,
                                            isPersistentHintChild: field.isPersistentHintChild,
                                            isRequired: field.isRequired,
                                            isMultiple: field.isMultiple,
                                            defaultValue: field.defaultValue,
                                            defaultValueSecond: field.defaultValueSecond,
                                            directoryIdSecond: field.directoryIdSecond,
                                            isMultipleSecond: field.isMultipleSecond,
                                            labelSecond: field.labelSecond,
                                            hintSecond: field.hintSecond,
                                            isPersistentHintSecond: field.isPersistentHintSecond,
                                            settings: field.settings,
                                          }" />
          <DynamicCheckListField v-if="field.type === 'checklist' && checkPermissions(field.groups)
          "
                                 v-model="values[buildValueKey(field.localName)]"
                                 :options="{
                                  label: field.label,
                                  hint: field.hint,
                                  isPersistentHint: field.isPersistentHint,
                                  labelChild: field.labelChild,
                                  hintChild: field.hintChild,
                                  isPersistentHintChild: field.isPersistentHintChild,
                                  isRequired: field.isRequired,
                                  defaultValue: field.defaultValue,
                                  settings: field.settings,
                                }" />
          <DynamicDatePickerField v-if="field.type === 'datepicker' && checkPermissions(field.groups)"
                                  v-model="values[buildValueKey(field.localName)]"
                                  :options="{
                                    label: field.label,
                                    hint: field.hint,
                                    isPersistentHint: field.isPersistentHint,
                                    isRequired: field.isRequired,
                                    defaultValue: field.defaultValue,
                                  }" />



          <DynamicDirectoryRedmineField v-if="field.type === 'redmine-list' && checkPermissions(field.groups)"
                                        v-model="values[buildValueKey(field.localName)]"
                                        :project-id="projectId"
                                        :options="field" />

          <DynamicTextField v-if="field.type === 'redmine-param' && checkPermissions(field.groups)"
                            v-model="values[buildValueKey(field.localName)]"
                            :options="field" />


        </div>
      </v-card-text>
      <v-card-text v-else>
        <div class="py-0"
             v-for="field in card.fields"
             :key="field.id">
          <DynamicFileField v-if="field.type === 'file' && checkPermissions(field.groups)"
                            :options="{
                              label: field.label,
                              hint: field.hint,
                              isPersistentHint: field.isPersistentHint,
                            }"
                            @change="handleChangeFiles" />

          <DynamicTextField v-if="field.type === 'text' && checkPermissions(field.groups)"
                            v-model="values[buildValueKey(field.localName)]"
                            :options="{
                              label: field.label,
                              hint: field.hint,
                              isPersistentHint: field.isPersistentHint,
                              isRequired: field.isRequired,
                              defaultValue: field.defaultValue,
                              mask: field.mask
                            }" />

          <DynamicEmailField v-if="field.type === 'email' && checkPermissions(field.groups)"
                             v-model="values[buildValueKey(field.localName)]"
                             :options="{
                              label: field.label,
                              hint: field.hint,
                              isPersistentHint: field.isPersistentHint,
                              isRequired: field.isRequired,
                              defaultValue: field.defaultValue,
                            }" />
          <DynamicRadioGroupField v-if="field.type === 'radio-buttons' && checkPermissions(field.groups)
          "
                                  v-model="values[buildValueKey(field.localName)]"
                                  :options="{
                                    label: field.label,
                                    hint: field.hint,
                                    isPersistentHint: field.isPersistentHint,
                                    labelChild: field.labelChild,
                                    hintChild: field.hintChild,
                                    isPersistentHintChild: field.isPersistentHintChild,
                                    isRequired: field.isRequired,
                                    defaultValue: field.defaultValue,
                                    settings: field.settings,
                                  }" />
          <DynamicSwitchField v-if="field.type === 'switch' && checkPermissions(field.groups)"
                              v-model="values[buildValueKey(field.localName)]"
                              :options="{
                                label: field.label,
                                hint: field.hint,
                                isPersistentHint: field.isPersistentHint,
                                labelChild: field.labelChild,
                                hintChild: field.hintChild,
                                isPersistentHintChild: field.isPersistentHintChild,
                                isRequired: field.isRequired,
                                defaultValue: field.defaultValue,
                              }" />
          <DynamicSwitchTextField v-if="field.type === 'switch-text' && checkPermissions(field.groups)
          "
                                  v-model="values[field.localName]"
                                  :options="{
                                    label: field.label,
                                    hint: field.hint,
                                    isPersistentHint: field.isPersistentHint,
                                    labelChild: field.labelChild,
                                    hintChild: field.hintChild,
                                    isPersistentHintChild: field.isPersistentHintChild,
                                    isRequired: field.isRequired,
                                    defaultValue: field.defaultValue,
                                  }" />
          <DynamicTextAreaField v-if="field.type === 'textarea' && checkPermissions(field.groups)"
                                v-model="values[buildValueKey(field.localName)]"
                                :options="{
                                  label: field.label,
                                  hint: field.hint,
                                  rows: field.rows,
                                  isPersistentHint: field.isPersistentHint,
                                  isRequired: field.isRequired,
                                  defaultValue: field.defaultValue,
                                }" />
          <DynamicDirectoryField v-if="field.type === 'directory' && checkPermissions(field.groups)"
                                 v-model="values[buildValueKey(field.localName)]"
                                 :options="{
                                  label: field.label,
                                  hint: field.hint,
                                  directoryId: field.directoryId,
                                  isUseValue: field.isUseValue,
                                  isPersistentHint: field.isPersistentHint,
                                  isRequired: field.isRequired,
                                  isMultiple: field.isMultiple,
                                  defaultValue: field.defaultValue,
                                  isEnableOtherValue: field.isEnableOtherValue
                                }" />
          <DynamicDirectorySwitchField v-if="field.type === 'switch-directory' &&
            checkPermissions(field.groups)
          "
                                       v-model="values[buildValueKey(field.localName)]"
                                       :options="{
                                        label: field.label,
                                        hint: field.hint,
                                        directoryId: field.directoryId,
                                        isUseValue: field.isUseValue,
                                        isPersistentHint: field.isPersistentHint,
                                        labelChild: field.labelChild,
                                        hintChild: field.hintChild,
                                        isPersistentHintChild: field.isPersistentHintChild,
                                        isRequired: field.isRequired,
                                        isMultiple: field.isMultiple,
                                        defaultValue: field.defaultValue,
                                      }" />
          <DynamicTwoDirectorySwitchField v-if="field.type === 'two-directory' && checkPermissions(field.groups)
          "
                                          v-model="values[buildValueKey(field.localName)]"
                                          :options="{
                                            label: field.label,
                                            hint: field.hint,
                                            directoryId: field.directoryId,
                                            isUseValue: field.isUseValue,
                                            isPersistentHint: field.isPersistentHint,
                                            labelChild: field.labelChild,
                                            hintChild: field.hintChild,
                                            isPersistentHintChild: field.isPersistentHintChild,
                                            isRequired: field.isRequired,
                                            isMultiple: field.isMultiple,
                                            defaultValue: field.defaultValue,
                                            defaultValueSecond: field.defaultValueSecond,
                                            directoryIdSecond: field.directoryIdSecond,
                                            isMultipleSecond: field.isMultipleSecond,
                                            labelSecond: field.labelSecond,
                                            hintSecond: field.hintSecond,
                                            isPersistentHintSecond: field.isPersistentHintSecond,
                                            settings: field.settings,
                                          }" />
          <DynamicCheckListField v-if="field.type === 'checklist' && checkPermissions(field.groups)
          "
                                 v-model="values[buildValueKey(field.localName)]"
                                 :options="{
                                  label: field.label,
                                  hint: field.hint,
                                  isPersistentHint: field.isPersistentHint,
                                  labelChild: field.labelChild,
                                  hintChild: field.hintChild,
                                  isPersistentHintChild: field.isPersistentHintChild,
                                  isRequired: field.isRequired,
                                  defaultValue: field.defaultValue,
                                  settings: field.settings,
                                }" />
          <DynamicDatePickerField v-if="field.type === 'datepicker' && checkPermissions(field.groups)"
                                  v-model="values[buildValueKey(field.localName)]"
                                  :options="{
                                    label: field.label,
                                    hint: field.hint,
                                    isPersistentHint: field.isPersistentHint,
                                    isRequired: field.isRequired,
                                    defaultValue: field.defaultValue,
                                  }" />

          <DynamicDirectoryRedmineField v-if="field.type === 'redmine-list' && checkPermissions(field.groups)"
                                        v-model="values[buildValueKey(field.localName)]"
                                        :project-id="projectId"
                                        :options="field" />

          <DynamicTextField v-if="field.type === 'redmine-param' && checkPermissions(field.groups)"
                            v-model="values[buildValueKey(field.localName)]"
                            :options="field" />

        </div>
      </v-card-text>
      <v-card-actions v-if="isDuplicate">
        <v-spacer></v-spacer>
        <v-btn variant="text"
               @click="handleAdd">
          Добавить
        </v-btn>
      </v-card-actions>
    </v-card>
  </div>
</template>

<style scoped></style>
