<script setup lang="ts">
import type { IDynamicComponentsOption } from "~/utils/components";
import { useRules } from "~/composables/useRules";
import type { ComputedRefSymbol } from "@vue/reactivity";

interface Props {
  modelValue: string | undefined;
  options: IDynamicComponentsOption;
}

const props = withDefaults(defineProps<Props>(), {});

const emit = defineEmits<{
  "update:modelValue": [payload: string];
}>();

const updateValue = (e: string) => {
  emit("update:modelValue", e);
};


const { ruleRequired } = useRules();

const { elementMarginTop, elementDensity } = useRuntimeConfig().public.forms;
const classObject = computed(() => `mt-${elementMarginTop}`)
// maska
function onAccept(e) {
  console.log('onAccept', e);

}
const maska = props.options.mask ? props.options.mask : undefined
// console.log(`maska:`, props.options.label, ' = ', maska);
// console.log(`options:`, props.options);

</script>

<template>
  <div :class="classObject">
    <!-- <input type="hidden"
           :value="modelValue"
           @input="updateValue($event.target.value)"
           v-maska
           :data-maska="maska" /> -->

    <v-text-field :label="options.label"
                  :hint="options.hint"
                  :persistent-hint="options.isPersistentHint"
                  :disabled="options.disabled"
                  :value="modelValue"
                  :density="elementDensity"
                  v-imask="maska"
                  @accept="onAccept"
                  @update:model-value="updateValue"
                  :rules="options.isRequired ? ruleRequired : undefined" />



  </div>
</template>

<style scoped></style>
