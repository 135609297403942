<script setup lang="ts">
import { storeToRefs } from "pinia";
import { useProjectsStore } from "@/stores/db/projects";
import { type IForm, type IUserGroups } from "@/types";
import { type IFormCard } from "@/types";
import { useUIStore } from '@/stores/ui';
const { isWindows,
  isAndroid,
  isIos,
  isMacOS,
  isFirefox,
  isEdge,
  isChrome,
  isSafari,
  userAgent,
} = useDevice()


const props = defineProps<{
  formId: number;
}>();

const emit = defineEmits<{
  (e: "@endDispatch", formId: number, issueId: number): void;
}>();

interface IRedmineResponse {
  success: boolean;
  issueId: number;
}
const { data: sessionData } = useAuth()
const { currentProject } = storeToRefs(useProjectsStore());
const { clientIp } = storeToRefs(useUIStore())
const form = ref();
const { $toast } = useNuxtApp();
const cards = ref<IFormCard[]>();
const currentForm = ref<IForm>();
const userGroups = ref<string[]>([]);
const formValues = ref<any>({});
const loading = ref(false);

const duplCards = ref<(IFormCard | undefined)[]>([]); // for duplicate
const duplCounts = ref(0);
const duplCardKey = ref("");
const isFormEmpty = ref(false);
const agreementChecked = ref(false)

const files = ref();
//***************************************** methods
const findCardsByForm = async (formId: number) => {
  const { data, error } = await useCustomFetch<IFormCard[]>(
    `forms/cards/${formId}`
  );
  if (error.value) {
    ce(
      "components.dispatch.form.findCardsByFormId",
      `error: ${JSON.stringify(error.value)}`
    );
  } else {
    cards.value = data.value as IFormCard[];
    isFormEmpty.value = cards.value?.length === 0;
    // cl(
    //   "components.dispatch.form.findCardsByFormId",
    //   `DATA len: ${(data.value as IFormCard[]).length}`
    // );
    // cl(
    //   "components.dispatch.form.findCardsByFormId",
    //   `length: ${cards.value.length}`
    // );
  }
};
const fetchForm = async () => {
  const { data, error } = await useCustomFetch<IForm>(`forms/${props.formId}`);
  if (error.value) {
    ce(
      "components.dispatch.form.fetchForm",
      `error: ${JSON.stringify(error.value)}`
    );
  } else {
    currentForm.value = data.value as IForm;
  }
};

const fetchUserGroups = async (): Promise<string[]> => {
  if (!sessionData.value?.id) return []

  const { data, error } = await useCustomFetch<IUserGroups[]>(
    `users/groups/${sessionData.value?.id}`
  );
  if (error.value) {
    ce(
      "components.dispatch.form.fetchUserGroups",
      `error: ${JSON.stringify(error.value)}`
    );
    return [];
  } else {
    if (data.value)
      return data.value
        ?.filter(
          (item) => item.projectId == currentProject.value?.id && item.isChecked
        )
        .map((item) => {
          return item.groupTitle;
        });
    else return [];
  }
};


const removeUnusedValues = () => {
  if (!formValues.value[duplCardKey.value]) return;
  let index = formValues.value[duplCardKey.value].length;
  while (index--) {
    const found = duplCards.value.find(
      (item) => item?.index == formValues.value[duplCardKey.value][index].index
    );
    if (!found) {
      console.log(
        "removeUnusedValues",
        formValues.value[duplCardKey.value][index].index
      );
      formValues.value[duplCardKey.value].splice(index, 1);
    }
  }
};

const handleSubmitForm = async () => {
  if (loading.value) return;
  const { valid } = await form.value.validate();
  if (!valid) {
    ce('components.dispatch.form.handleSubmitForm"', "FORM NOT VALID");
    return;
  }
  // console.warn(formData.value.isUseAgreement, agreementChecked.value);


  if (formData.value.isUseAgreement && !agreementChecked.value) {
    $toast.error('Подвердите согласие на обработку персональных данных!');
    return
  }
  loading.value = true;

  removeUnusedValues();
  cl(
    "components.dispatch.form.handleSubmitForm",
    `JSON values: ${JSON.stringify(formValues.value)}`
  );

  const userInfo = await getUserInfo()
  const formDataRedmine = new FormData();
  formDataRedmine.append("data", JSON.stringify(formValues.value));
  formDataRedmine.append("user", JSON.stringify(userInfo));
  // formData.append("data", formValues.value);
  // formData.append("data", JSON.stringify({ foo: "bar" }));
  // cl(
  //   "components.dispatch.form.handleSubmitForm",
  //   `values: ${formValues.value}`
  // );
  if (files.value) {
    for (let i = 0; i < files.value.length; i++) {
      formDataRedmine.append("files", files.value[i]);

    }
    formDataRedmine.getAll("files").forEach((file) => {
      console.log(`file added: ${file.name}, ${file.type}`);
    });
  }

  const { data, error } = await useCustomFetch<IRedmineResponse>(`redmine`, {
    method: "POST",
    body: formDataRedmine,
  });
  if (error.value) {
    console.error(
      `[components.dispatch.form.handleSubmitForm] error: `,
      buildErrorMessage(error.value)
    );
    $toast.error(buildErrorMessage(error.value));
  } else {
    // console.log(
    //   `[components.dispatch.form.handleSubmitForm] data: `,
    //   data.value
    // );
    $toast.success(`Задача ${data.value?.issueId} добавлена.`);
    cl(
      "components.dispatch.form.handleSubmitForm",
      `OK data: ${JSON.stringify(data.value)}`
    );
    emit("@endDispatch", props.formId, data.value?.issueId);
  }

  loading.value = false;
};

const buildErrorMessage = (error: any) => {
  const obj = JSON.parse(JSON.stringify(error));
  if (obj.data.message) return obj.data.message;
  else {
    return obj.message;
  }
};


const initForm = () => {
  // $toast.success(`initForm ${props.cards.length}`);
  formValues.value = null;
  formValues.value = {};
  formValues.value["FORM_ID"] = props.formId.toString();
  formValues.value["FILES_ID"] = undefined;

  if (!currentForm.value?.isDuplicateFirstCard) {
    for (let i = 0; i < cards.value?.length; i++) {
      formValues.value[cards.value[i].id?.toString()] = {};
      for (let j = 0; j < cards.value[i].fields.length; j++) {
        formValues.value[cards.value[i].id?.toString()][
          cards.value[i].fields[j].localName
        ] = setDefaultValues(cards.value[i].fields[j]);
        // console.log(`defaultValue: ${cards.value[i].fields[j].defaultValue}`);
        // setDefaultValues(cards.value[i].fields[j]);
      }
    }
  } else {
    // duplicate card
    let obj: any = {};
    let tmpCards: any = [];
    for (let j = 0; j < cards.value[0].fields.length; j++) {
      obj[cards.value[0].fields[j].localName + `-${duplCounts.value}`] = "";
    }

    obj["index"] = duplCounts.value;
    duplCardKey.value = cards.value[0].id?.toString();
    formValues.value[duplCardKey.value] = [obj];

    // other
    for (let i = 1; i < cards.value?.length; i++) {
      formValues.value[cards.value[i].id?.toString()] = {};
      for (let j = 0; j < cards.value[i].fields.length; j++) {
        formValues.value[cards.value[i].id?.toString()][
          cards.value[i].fields[j].localName
        ] = setDefaultValues(cards.value[i].fields[j]);
      }
    }

    // duplCards.value[0] = cards.value?.shift();
    duplCards.value[0] = { ...cards.value[0] };
    duplCards.value[0] = { ...duplCards.value[0], index: duplCounts.value };

    for (let i = 1; i < cards.value?.length; i++) {
      tmpCards.push(cards.value[i]);
    }
    cards.value = tmpCards;

    // cl("**** form cards", JSON.stringify(cards.value));
    // cl("**** form dupl", JSON.stringify(duplCards.value));
  }
};

const handleAddCard = (index: number) => {
  cl("components.dispatch.form.handleAddCard", `index: ${index}`);

  duplCounts.value++;

  let obj: any = {};
  for (let j = 0; j < duplCards.value[0].fields.length; j++) {
    obj[duplCards.value[0].fields[j].localName + `-${duplCounts.value}`] = "";
  }

  obj["index"] = duplCounts.value;
  formValues.value[duplCardKey.value].push(obj);

  duplCards.value.push({ ...duplCards.value[0], index: duplCounts.value });

};

const handleRemoveCard = (index: number) => {
  cl("components.dispatch.form.handleRemoveCard", `index: ${index}`);
  cl(
    "components.dispatch.form.handleRemoveCard",
    `duplCards: ${JSON.stringify(duplCards.value)}`
  );

  const found = duplCards.value.find((item) => {
    if (item) {
      return item.index === index;
    }
  });
  if (found) {
    // remove card
    const foundIndex = duplCards.value.indexOf(found);
    // cl(
    //   "components.dispatch.form.handleRemoveCard",
    //   `index: ${index}, foundIndex: ${foundIndex} )}`
    // );
    if (foundIndex !== -1) {
      // duplCards.value.splice(foundIndex, 1);
      duplCards.value[foundIndex] = undefined;
    }

    // remove values
    // const v = formValues.value[duplCardKey.value].find(
    //   (item: any) => item.index == index
    // );
    // const i = formValues.value[duplCardKey.value].indexOf(v);
    // formValues.value[duplCardKey.value].splice(i, 1);
  }
};

const handleFileInput = (fls: any) => {
  files.value = fls
};

const setDefaultValues = (item: any): any => {
  switch (item.type) {
    case "switch-directory":
    case "directory":
    case "textarea":
    case "switch-text":
    case "radio-buttons":
    case "text":
      return item.defaultValue ? item.defaultValue : "";
    case "switch": {
      if (item.defaultValue) {
        if (item.defaultValue === "true") return true;
        else return false;
      } else return false;
    }
    case "checklist": {
      if (item.defaultValue) {

        const arrayValues = item.defaultValue?.split(/\r?\n/);
        let result: string = ""
        let color: string;
        arrayValues?.forEach((it: string) => {
          const title = it.split("$")[0];
          if (it.split("$")[1]) {
            color = it.split("$")[1].trim();
          }
          if (!result) result = title
          else result = result + ', ' + title
        });


        return result
      } else return "";
    }
    default:
      return "";
  }
};
// const clientOS = useCookie('fo-os')

// os
const definePlatform = () => {
  const defineOS = () => {
    if (isWindows) return 'Windows'
    if (isAndroid) return 'Android'
    if (isIos) return 'iOS'
    if (isMacOS) return 'MacOS'
    return userAgent
  }
  const defineBrowser = () => {
    if (isChrome) return 'Chrome'
    if (isEdge) return 'Edge'
    if (isFirefox) return 'Firefox'
    if (isSafari) return 'Safari'
    return userAgent
  }
  if ((!defineOS()) || (!defineBrowser()))
    return userAgent
  else
    return `${defineOS()}, ${defineBrowser()}`
}

// 
async function getUserInfo() {
  // const ip = await $fetch('https://api.ipify.org/');
  return {
    userId: sessionData.value?.id,
    ip: clientIp.value,
    os: definePlatform(),
    tz: (new Date()).toString().match(/([A-Z]+[\+-][0-9]+.*)/)[1]
  }

}
const sendFakeForm = async () => {
  const userInfo = await getUserInfo()
  console.log('[sendFakeForm] user: ', JSON.stringify(userInfo));
  // formValues.value = { "102": { "EMPLOYEE_FIO": "efioe", "EMPLOYEE_CITY": "chel", "EMPLOYEE_JOB_TITLE": "manager", "REDMINE_PRIORITY": 7, "GUEST_EMAIL": "adf@com.es" }, "FORM_ID": "169" }
  formValues.value = { "102": { "EMPLOYEE_FIO": "ee", "EMPLOYEE_CITY": "ee", "EMPLOYEE_JOB_TITLE": "ee", "REDMINE_PRIORITY": "", "GUEST_EMAIL": "ee@a.com" }, "FORM_ID": "1" }


  const formData = new FormData();
  formData.append("user", JSON.stringify(userInfo));
  formData.append("data", JSON.stringify(formValues.value));



  const { data, error } = await useCustomFetch<IRedmineResponse>(`redmine`, {
    method: "POST",
    body: formData,
  });
  if (error.value) {
    console.error(
      `[components.dispatch.form.handleSubmitForm] error: `,
      buildErrorMessage(error.value)
    );
    $toast.error(buildErrorMessage(error.value));
  } else {
    // console.log(
    //   `[components.dispatch.form.handleSubmitForm] data: `,
    //   data.value
    // );
    $toast.success(`Задача ${data.value?.issueId} добавлена.`);
    cl(
      "components.dispatch.form.handleSubmitForm",
      `OK data: ${JSON.stringify(data.value)}`
    );
    emit("@endDispatch", props.formId, data.value?.issueId);
  }

  loading.value = false;
};

await fetchForm();
if (!sessionData.value?.id) {
  if (!currentForm.value?.isAccessNotAuthorizedUser) {
    throw createError({
      message: "У вас нет доступа к этой форме",
      statusCode: 401,
    });
  }
}

await findCardsByForm(props.formId);
userGroups.value = await fetchUserGroups();
initForm();

// console.log("groups: ", JSON.stringify(userGroups.value));
const { data: formData } = await useCustomFetch<IForm>(`forms/${props.formId}`);

useHead({
  title: `Заявка в техподдержку - ${formData.value?.title}`,
});

</script>

<template>
  <div>
    <v-row dense>
      
      <v-col cols="12">
        <v-card v-if="formData?.infoContent && formData?.infoTitle"
                density="compact"
                class="mb-2">
          <v-card-title>{{ formData?.infoTitle }}</v-card-title>
          <v-card-text v-html="formData.infoContent"></v-card-text>
        </v-card>

        <v-card v-if="formData?.isReglementPanelVisible"
                density="compact"
                class="mb-2">
          <v-card-text class="d-flex justify-space-evenly ">
            <v-btn class="mr-16"
                   variant="text"
                   :disabled="!formData.knowledgeBaseLink">
              <NuxtLink v-if="formData.knowledgeBaseLink"
                        :to="formData.knowledgeBaseLink">
                {{
                  formData.knowledgeBaseLink
                    ? "База знаний"
                    : "База знаний (Нет ссылки)"
                }}
              </NuxtLink>
            </v-btn>
            <v-btn variant="text"
                   :disabled="!formData.handFormLink">
              <NuxtLink v-if=formData.handFormLink
                        :to="formData.handFormLink">
                {{
                  formData.handFormLink ? "Ссылка на рук. форму" : "Ссылка на рук. форму (Нет ссылки)"
                }}
              </NuxtLink>
            </v-btn>
            <v-btn variant="text"
                   :disabled="!formData.reglementLink">
              <NuxtLink v-if="formData.reglementLink"
                        :to="formData.reglementLink">
                {{
                  formData.reglementLink ? "Регламент" : "Регламент (Нет ссылки)"
                }}
              </NuxtLink>
            </v-btn>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
    <v-row dense>
      <v-col cols="12">
        <v-form v-if="!isFormEmpty"
                ref="form"
                @submit.prevent="handleSubmitForm">
          <v-card>
            <v-container>
              <div v-if="currentForm?.isDuplicateFirstCard">
                <div v-for="card in duplCards">
                  <DispatchCard v-if="card"
                                :project-id="currentForm!.projectId"
                                :index="duplCounts"
                                :card="card"
                                :user-groups="userGroups"
                                :is-duplicate="true"
                                @@add-card="handleAddCard($event)"
                                @@remove-card="handleRemoveCard($event)"
                                @@handle-files="handleFileInput($event)"
                                :values="formValues[duplCardKey][card.index]" />
                </div>
                <DispatchCard v-for="card in cards"
                              :project-id="currentForm!.projectId"
                              :key="card.id"
                              :card="card"
                              :user-groups="userGroups"
                              :is-duplicate="false"
                              :values="formValues[card.id!.toString()]"
                              @@handle-files="handleFileInput($event)" />
              </div>

              <DispatchCard v-else
                            v-for="card in cards"
                            :project-id="currentForm!.projectId"
                            :key="card.id"
                            :card="card"
                            :user-groups="userGroups"
                            :is-duplicate="false"
                            :values="formValues[card.id!.toString()]"
                            @@handle-files="handleFileInput($event)" />
            </v-container>
          </v-card>
          <div v-if="formData!.isUseAgreement"
               class="d-flex justify-space-between align-center ml-2 mr-2 ">
            <v-switch inset
                      v-model="agreementChecked"
                      :color="agreementChecked ? 'blue-darken-3' : 'red'"
                      label="Я согласен на обработку моих персональных данных" />
            <NuxtLink :to="formData!.agreementLink"
                      target="_blank">Скачать согласие</NuxtLink>
          </div>
          <div class="mx-auto mt-4">
            <v-btn block
                   class="mb-4"
                   color="primary"
                   size="large"
                   type="submit"
                   :loading="loading">Добавить задачу</v-btn>
          </div>
        </v-form>
      </v-col>
    </v-row>
    <v-row>
      
    </v-row>
  </div>
</template>

<style scoped></style>
